/* global ApeConfig */
import React from 'react';
import axios from 'axios';
import { Typography, Flex } from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ApeGrid, Item } from './shared/ApeGrid'
import { useDebouncedValue } from './ViewToken'
import { useSockets } from './context/SocketsContext';
import { useGrid } from './context/GridContext';
//const { useBreakpoint } = Grid;
const { Text } = Typography;

const HeroItem = ({ value, isSolo = true }) => {

	const config = ApeConfig.find(x => x.ID === value.chain);
	const chainName = config.Short.length <= 3 ? config.Short.toUpperCase() : config.Short.charAt(0).toUpperCase() + config.Short.slice(1);

	return (
		<div style={{
			width: '344px',
			height: '184px',
			background: 'linear-gradient(180deg, #FFE814 0%, #E2CE19 13%, #857B29 54.5%, #998B0C 100%)',
			borderRadius: '20px',
			padding: '2px',
			position: 'relative'
		}}>
			<div style={{
				width: '50px',
				height: '50px',
				position: 'absolute',
				top: '-20px',
				left: '-20px',
				zIndex: 1,
				background: 'linear-gradient(180deg, #FFE814 0%, #E2CE19 13%, #857B29 54.5%, #998B0C 100%)',
				borderRadius: '50%',
				padding: '2px',
			}}>
				<div style={{
					backgroundColor: '#191a1a',
					borderRadius: '50%',
					padding: 0,
					width: '100%',
					height: '100%'
				}}>
					<img alt="King of Apes" style={{ width: '46px', height: '46px'}} src="/img/crown.png" />
				</div>
			</div>
			<Text style={{
				position: 'absolute',
				top: '-30px',
				paddingLeft: '35px',
				left: 0,
				fontSize: '17px',
				fontWeight: 500,
				lineHeight: '20.57px',
				textAlign: 'left',
			}}>King Of {isSolo ? "Apes" : chainName}</Text>
			<Item value={value} isKing={true} />
		</div>
	);
}

const FreeItem = ({ value }) => {
	return (
		<div style={{
			width: '344px',
			height: '184px',
			background: 'linear-gradient(270.06deg, #86EFAC -20.5%, #4D8963 78.59%)',
			borderRadius: '20px',
			padding: '2px',
			position: 'relative'
		}}>
			<div style={{
				width: '50px',
				height: '50px',
				position: 'absolute',
				top: '-20px',
				left: '-20px',
				zIndex: 1,
				background: 'linear-gradient(270.06deg, #86EFAC -20.5%, #4D8963 78.59%)',
				borderRadius: '50%',
				padding: '2px',
			}}>
				<div style={{
					backgroundColor: '#191a1a',
					borderRadius: '50%',
					padding: 0,
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<img alt="Recently Freed" style={{ width: '40px', height: '30px' }} src="/img/logo.png" />
				</div>
			</div>
			<Text style={{
				position: 'absolute',
				top: '-30px',
				paddingLeft: '35px',
				left: 0,
				fontSize: '17px',
				fontWeight: 500,
				lineHeight: '20.57px',
				textAlign: 'left',
			}}>Recently Freed</Text>
			<Item value={value} isKing={true} />
		</div>
	);
}

const ApeNewsWidget = ({ topTokens, freeTokens }) => {
	return (
		<div style={{ zIndex: 1, position: "relative", background: 'rgb(27, 29, 40)' }}>
			<div style={{
				background: 'url("/img/apebg2.jpg")',
				backgroundSize: 'cover',
				scale: 'crop',
				width: '100%',
				height: '325px',
				opacity: '0.03',
				angle: '-180 deg',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}} />
			<div style={{ position: 'absolute', width: '100%', height: '325px', top: 0 }}>
				<Slider
					infinite={true}
					speed={500}
					slidesToShow={1}
					slidesToScroll={1}
					centerMode={true}
					autoplay={true}
					arrows={false}
					autoplaySpeed={5000}
					centerPadding="10px"
				> 
					{topTokens.map(token => {
						return (
							<div key={token.id} style={{ width: '90%', minWidth: '345px'}}>
								<div style={{ width: '100%', height: '325px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
									<HeroItem value={token} isSolo={topTokens.length <= 1} />
								</div>
							</div>
						);
					})}
					{freeTokens.map(token => {
						return (
							<div key={token.id} style={{ width: '90%', minWidth: '345px' }}>
								<Flex vertical justify="center" align="center" style={{ height: '325px' }}>
									<Flex wrap="wrap" justify="center" align="center" gap={10}>
										<FreeItem value={token} />
									</Flex>
								</Flex>
							</div>
						);
					})}
				</Slider>
			</div>
		</div>	
	);
}

export const Home = () => {
	const { latestToken, latestEvent, refreshRequired, setRefreshRequired } = useSockets();
	const { sort, setSort, chain, setChain, order, setOrder, search, setSearch, filter, setFilter, page, setPage, pages, setPages } = useGrid();

	const [tokens, setTokens] = React.useState([]);
	const [topTokens, setTopTokens] = React.useState([]);
	const [freeTokens, setFreeTokens] = React.useState([]);

	const debouncedSearch = useDebouncedValue(search, 500);

	const loadKing = async () => {
		setTopTokens([]);
		const response = await axios.get(`/api/king`);
		setTopTokens(response.data.king);
		setFreeTokens(response.data.free)
	};

	const loadPage = async () => {
		setTokens([]);
		const response = await axios.get(`/api/tokens?page=${page}&sort=${sort}&order=${order}&filter=${filter}&search=${search}&chain=${chain}`);
		setPages(response.data.pageCount);
		setTokens(response.data.items);
	};

	React.useEffect(() => {
		loadPage();
	}, [page, sort, order, filter, debouncedSearch, chain]);


	React.useEffect(() => {
		loadKing();
	}, [])

	React.useEffect(() => {
		if (refreshRequired) {
			loadPage();
			loadKing();
			setRefreshRequired(false);
		}
	}, [refreshRequired]);

	React.useEffect(() => {

		if (tokens.length === 0) {
			return;
		}

		if (latestToken !== null) {
			for (let i = 0; i < topTokens.length; i++) {
				if (latestToken.id === topTokens[i].id) {
					setTopTokens([latestToken, ...topTokens.filter(x => x.chain !== latestToken.chain)]);
				}
			}
			for (let i = 0; i < freeTokens.length; i++) {
				if (latestToken.id === freeTokens[i].id) {
					setFreeTokens([latestToken, ...freeTokens.filter(x => x.chain !== latestToken.chain)]);
				}
			}
		}			

		if (latestToken !== null && page === 0 && sort === 1) {
			const t = tokens.filter(x => x.id !== latestToken.id);
			setTokens([{ animate: true, ...latestToken }, ...t].splice(0, 18));
			// TODO - other sort orders / pages?
		} else {
			setTokens(tokens?.map(el => (el.id === latestToken?.id ? { ...el } : el)));
		}
	}, [latestToken]);

	React.useEffect(() => {
		if (latestEvent?.isNewKing || latestEvent?.isNewFree) {
			console.log("New king detected")
			loadKing();
		}
	}, [latestEvent]);

	return (
		<>			
			<ApeNewsWidget topTokens={topTokens} freeTokens={freeTokens}  />	
			<ApeGrid title="Real-time Pairs" items={tokens} page={page} setPage={setPage} sort={sort} setSort={setSort} order={order} setOrder={setOrder} search={search} setSearch={setSearch} filter={filter} setFilter={setFilter} chain={chain} setChain={setChain} pages={pages} />
		</>
	);
};

import React from 'react';
import { Button } from 'antd';
import { useWeb3Modal, useDisconnect, useWeb3ModalAccount } from '@web3modal/ethers/react'

export const ConnectButton = () => {
	const { open } = useWeb3Modal();
	const { disconnect } = useDisconnect();
	const { isConnected } = useWeb3ModalAccount()

	return (
		isConnected ? <Button onClick={() => disconnect()}>Disconnect</Button> : <Button onClick={() => open()}>Connect</Button>
	);
}
/* global ApeConfig */
import React from 'react';
import { Typography, Layout, Flex, Divider, Button, Image, theme } from 'antd';
import { useNavigate } from "react-router-dom";
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { useSockets } from '../context/SocketsContext';
import { TokenLogo } from '../shared/TokenLogo';
import { ConnectButton } from '../shared/ConnectButton';
import { ChainSelect } from '../shared/ChainSelect'
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useAnimation } from '../context/AnimationContext';
import { UserIcon } from '../shared/UserIcon';
const { Link } = Typography;
const { Header } = Layout;

export const TopMenu = ({ mobile, setMenuCollapsed, handleChainChange }) => {
	const {
		token: { bgTopMenu },
	} = theme.useToken();

	const { events } = useSockets();
	const navigate = useNavigate();
	const { chainId } = useWeb3ModalAccount();
	const { enableAnimation } = useAnimation();

	return (mobile ?
		<Header style={{ position: 'sticky', overflow: 'hidden', top: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: bgTopMenu, padding: '0 20px', height: '120px', boxShadow: 'rgb(0 0 0 / 40%) 0px 5px 20px 5px', zIndex: 100 }}>
			<Flex justify="space-between" align="center" style={{ width: '100%', background: 'rgb(27, 29, 40)', zIndex: 1, }}>
				<Link onClick={() => { navigate("/"); }}>
					<Flex align="center" >
						<Image preview={false}
							width={53}
							src="/img/logo.png"
						/>
						<Typography style={{ fontSize: "22px", fontWeight: 500, padding: '0 6px', overflow: 'hidden', textWrap: 'nowrap' }}>ApeStore</Typography>
					</Flex>
				</Link>
				<Flex align="center">
					<ChainSelect chainId={chainId} setChainId={handleChainChange} mobile={mobile} />
					<Button
						type="text"
						icon={<MenuUnfoldOutlined />}
						onClick={() => setMenuCollapsed(false)}
						style={{
							fontSize: '16px',
							width: 64,
							height: 64,
						}}
						/>
				</Flex>
			</Flex>
			<Divider style={{ padding: 0, margin: 0}} />
			<Flex align="center" justify="center" style={{ width: '100%' }}>
				{enableAnimation ?
					<TransitionGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						{events.slice(0, 1).map((event) => {
							return (
								<CSSTransition
									key={`${event.id}_t`}
									timeout={300}
									classNames="slide-down"
								>
									<Flex key={event.id} vertical={false} gap={10} wrap="nowrap" align="center" style={{ padding: "0", height: '50px', cursor: 'pointer' }} onClick={() => { navigate(`/${event.token.chain}/${event.token.address}`) }}>
										<UserIcon style={{ minWidth: '20px', height: '20px', width: '20px' }} address={event.address} />
										<Typography style={{ lineHeight: '1rem', textWrap: 'nowrap' }}>{event.message}</Typography>
										<TokenLogo value={event.token} size={20} />
									</Flex>
								</CSSTransition>
							)
						})}
					</TransitionGroup> :
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						{events.slice(0, 1).map((event) => {
							return (
								<div
									key={`${event.id}_t`}
									timeout={300}
									classNames="slide-down"
								>
									<Flex key={event.id} vertical={false} gap={10} wrap="nowrap" align="center" style={{ padding: "0", height: '50px', cursor: 'pointer' }} onClick={() => { navigate(`/${event.token.chain}/${event.token.address}`) }}>
										<UserIcon style={{ minWidth: '20px', height: '20px', width: '20px' }} address={event.address} />
										<Typography style={{ lineHeight: '1rem', textWrap: 'nowrap' }}>{event.message}</Typography>
										<TokenLogo value={event.token} size={20} />
									</Flex>
								</div>
							)
						})}
					</div> 
				}
			</Flex>
		</Header> :
		<Header style={{ position: 'sticky', top: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: bgTopMenu, padding: '0 20px', boxShadow: 'rgb(0 0 0 / 40%) 0px 5px 20px 5px', zIndex: 100 }}>
			<Flex vertical={false} align="center" justify="space-between" style={{ width: "100%" }}>
				<Flex vertical={false} align="center" style={{ overflow: 'hidden' }}>
					<div style={{
						background: 'rgb(27, 29, 40)', zIndex: 1, height: '30px', display: 'flex',
						alignItems: 'center',
						padding: '10px' }}>
						<div style={{ background: 'rgba(255, 199, 1, 0.16)', width: '13px', height: '13px', minWidth: '13px', border: 'solid 1px #C2A633', borderRadius: 100000 }} />
					</div>
					<Typography style={{
						fontWeight: 500, fontSize: '17px', color: '#fff', marginRight: '10px', textWrap: 'nowrap',
						lineHeight: '30px',
						background: 'rgb(27, 29, 40)',
						zIndex: 1,
						height: '30px'
					}}>Live -{'>'}</Typography>
					{enableAnimation ? <TransitionGroup style={{ display: 'flex', direction: 'row' }}>
						{events.map((item, index) => {
							return (
								<CSSTransition
									key={`${item.id}_t`}
									timeout={300}
									classNames="slide-in"
									unmountOnExit
									style={{ overflow: 'hidden', cursor: 'pointer' }}
								>
									<Flex key={item.id} vertical={false} gap={10} wrap="nowrap" align="center" style={{ padding: "0", height: '50px' }} onClick={() => { navigate(`/${ApeConfig.find(x => x.Short === item.token.chain || x.ID == item.token.chain)?.Short ?? item.token.chain}/${item.token.address}`) }}>
										<UserIcon style={{ minWidth: '20px', height: '20px', width: '20px' }} address={item.address} />
										<Typography style={{ lineHeight: '1rem', textWrap: 'nowrap' }}>{item.message}</Typography>
										<TokenLogo value={item.token} size={20} />
										{index < events.length - 1 ? <Divider type="vertical" style={{ height: "24px", borderColor: 'rgba(217, 217, 217, 0.4)', marginRight: '20px' }} /> : <></>}
									</Flex>
								</CSSTransition>
							)
						})}
					</TransitionGroup> :
						<div style={{ display: 'flex', direction: 'row' }}>
						{events.map((item, index) => {
							return (
								<div
									key={`${item.id}_t`}
									timeout={300}
									classNames="slide-in"
									unmountOnExit
									style={{ overflow: 'hidden', cursor: 'pointer' }}
								>
									<Flex key={item.id} vertical={false} gap={10} wrap="nowrap" align="center" style={{ padding: "0", height: '50px' }} onClick={() => { navigate(`/${ApeConfig.find(x => x.Short === item.token.chain || x.ID == item.token.chain)?.Short ?? item.token.chain}/${item.token.address}`) }}>
										<UserIcon style={{ minWidth: '20px', height: '20px', width: '20px' }} address={item.address} />
										<Typography style={{ lineHeight: '1rem', textWrap: 'nowrap' }}>{item.message}</Typography>
										<TokenLogo value={item.token} size={20} />
										{index < events.length - 1 ? <Divider type="vertical" style={{ height: "24px", borderColor: 'rgba(217, 217, 217, 0.4)', marginRight: '20px' }} /> : <></>}
									</Flex>
								</div>
							)
						})}
					</div>}
					
				</Flex>
				<div style={{display: 'flex', justifyContent: 'flex-end'
				}}>
					<div style={{ position: 'absolute', width: '200px', height: '40px', background: 'linear-gradient(90deg, rgba(27,29,40,0) 0%, rgba(27,29,40,1) 60%)' }}></div>
					<ConnectButton />
				</div>
			</Flex>
		</Header>
	);
}
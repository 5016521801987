/*global ApeConfig*/

import React from 'react';
import { Select, Flex, Radio } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
const { Text } = Typography;

const ChainImage = ({ src }) => {
	return <img src={src} width={20} height={20} />
}

export const ChainDisplay = ({ chain }) => {
	const config = ApeConfig.find(x => x.Short === chain.toString().toLowerCase() || x.ID === chain);
	return <ChainImage src={config.ChainLogo} />;
}

export const ChainToggle = ({ chainId, setChainId }) => {
	return (
		<Radio.Group value={chainId} onChange={(e) => { setChainId(e.target.value); }}>
			{ApeConfig.map(x => {
				return (
					<Radio.Button key={x.ID} value={x.ID} disabled={x.Active === false}>
						<Flex align="center" gap={5}>
							<ChainImage src={x.ChainLogo} />
							<Text>{x.Short.toUpperCase()}</Text>
						</Flex>
					</Radio.Button>
				);
			})}
		</Radio.Group>
	);
}

export const ChainSelect = ({ chainId, setChainId, mobile }) => {

	const supportedChains = [
		{
			value: 0,
			label: (
				<Flex align="center" justify="center" gap={5}>
					{mobile ? <Text>All</Text> : <Text>All Networks</Text>}
				</Flex>
			)
		}
	];

	for (let i = 0; i < ApeConfig.length; i++) {
		supportedChains.push({
			value: ApeConfig[i].ID,
			disabled: !ApeConfig[i].Active,
			label: (
				<Flex key={ApeConfig[i].ID} align="center" justify="center" gap={5}>
					<ChainImage src={ApeConfig[i].ChainLogo} />
					{mobile ? <></> : <Text>{ApeConfig[i].Name}</Text>}
				</Flex>
			),
		});
	}

	const labelRender = ({ label }) => {
		if (label) {
			return label;
		}

		return (
			<Flex align="center" gap={5}>
				{mobile ? <Text>All</Text> : <Text>All Networks</Text>}
			</Flex>
		);
	};

	const handleChainChange = (item) => {
		setChainId(item);
	}

	return (
		<Select
			style={{ width: mobile ? '80px' : '200px' }}
			labelRender={labelRender}
			suffixIcon={<DownOutlined style={{ height: 0 }} />}
			popupMatchSelectWidth={false}
			onChange={handleChainChange}
			value={chainId}
			options={supportedChains} />
	);
}

/*global ApeConfig*/

import React from 'react';
import axios from "axios";
import { formatEther, parseEther } from 'ethers/utils';
import { useNavigate } from "react-router-dom";
import { TokenInput } from './shared/TokenInput';
import { Col, Row,  Flex, Input, Image, Modal, Upload, Button, Checkbox, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { usePermissions } from './context/PermissionsContext'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider, Contract } from 'ethers'
import { Typography, message } from 'antd';
import { ApeStoreContractAbiV2 } from './shared/ApeStoreContract'
import { useDebouncedValue } from './ViewToken'
import { notification } from 'antd';
import { ChainToggle } from './shared/ChainSelect';
import { SwapIcon } from './shared/ApeIcons';
import { ConnectButton } from './shared/ConnectButton';

const { Text } = Typography;
const { TextArea } = Input;

export const NewToken = () => {
	const navigate = useNavigate();
	const { address, chainId, isConnected } = useWeb3ModalAccount()
	const { walletProvider } = useWeb3ModalProvider()
	const [api, contextHolder] = notification.useNotification();
	const { isAdmin, sessionId } = usePermissions();
	
	const [fileList, setFileList] = React.useState([]);
	const [name, setName] = React.useState("");
	const [symbol, setSymbol] = React.useState("");
	const [description, setDescription] = React.useState("");
	const [telegram, setTelegram] = React.useState("");
	const [twitter, setTwitter] = React.useState("");
	const [website, setWebsite] = React.useState("");
	const [hideToken, setHideToken] = React.useState(false);
	const [botTrap, setBotTrap] = React.useState(false);
	const [canSubmit, setCanSubmit] = React.useState(false);
	const [submitting, setSubmitting] = React.useState(false);
	const [deployFee, setDeployFee] = React.useState(null);

	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [buyAmount, setBuyAmount] = React.useState(0);
	const [amountOut, setAmountOut] = React.useState("0");
	const [nativeBalance, setNativeBalance] = React.useState(0);
	const debouncedAmountIn = useDebouncedValue(buyAmount, 500);

	const config = ApeConfig.find(x => x.ID === parseInt(chainId));

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	React.useEffect(() => {
		const isValid = (value) => {
			return value != null && value !== "";
		}
		setCanSubmit(isValid(name) && isValid(symbol) && isValid(address) && router && isConnected);
	}, [name, symbol, address, chainId, isConnected]);

	React.useEffect(() => {
		const getDeployFee = async () => {
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const apeStore = new Contract(router, ApeStoreContractAbiV2, signer);
			try {
				const fee = await apeStore.createFee();
				setDeployFee(formatEther(fee));
			} catch (ex) {
				setDeployFee(0);
			}
		}

		if (isConnected) {
			setDeployFee(null);
			getDeployFee();
		} else {
			setDeployFee(null);
		}
	}, [chainId, isConnected, walletProvider]);

	React.useEffect(() => {
		const getQuote = async () => {
			try {
				if (debouncedAmountIn > 0) {
					const reserve0 = parseEther("1006900000");
					const reserve1 = parseEther("0.9");
					const amountIn = parseEther(debouncedAmountIn);
					const tax = amountIn * parseEther("10") / parseEther("1000")
					const amount = amountIn - tax;
					console.log(amount , reserve0, reserve1)
					const out = (amount * reserve0) / (reserve1 + amount);
					console.log(out)
					setAmountOut(formatEther(out));
				} else {
					setAmountOut("0");
				}
			}
			catch (err) {
				console.error(err);
				setAmountOut(0);
			}
		};

		getQuote();
	}, [chainId, debouncedAmountIn]);

	React.useEffect(() => {
		const loadBalances = async () => {
			if (isConnected) {
				const ethersProvider = new BrowserProvider(walletProvider);
				setNativeBalance(formatEther((await ethersProvider.getBalance(address)).toString()));
			} else {
				setNativeBalance(0);
			}
		};

		loadBalances();
	}, [address, chainId, isConnected, walletProvider]);

	//if (!config) {
	//	return <Text>Invalid Chain</Text>
	//}

	const native = { address: config?.Wrapped, name: config?.NativeToken, symbol: config?.NativeToken, decimals: config?.NativeDecimals, logo: config?.NativeLogo };
	const router = config?.ApeRouters[config.ApeRouters.length - 1];

	const handleChainChange = async (chain) => {
		if (chain !== 0) {
			const provider = new BrowserProvider(walletProvider);
			const chainId = '0x' + (chain).toString(16);
			await provider.send('wallet_switchEthereumChain', [{ chainId }]);
		}
	}

	const handleAdminHide = async (e) => {
		setHideToken(e.target.checked);
	}

	const handleBotTrap = async (e) => {
		setBotTrap(e.target.checked);
	}

	const handleSubmit = async () => {
		setIsModalOpen(true);
	};

	const beforeUpload = (file) => {
		/*const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}*/
		const isOkSize = file.size / 1024  <= 512;
		if (!isOkSize) {
			message.error('Image must smaller than 512kb!');
		}
		return isOkSize;
	};

	const handleConfirm = async () => {
		try {
			setSubmitting(true);
			setIsModalOpen(false);

			const formData = new FormData();
			fileList.forEach((file) => {
				formData.append('files[]', file);
			});
			formData.append('data.Chain', chainId);
			formData.append('data.Creator', address);
			formData.append('data.Name', name);
			formData.append('data.Symbol', symbol);
			formData.append('data.Description', description);
			formData.append('data.Telegram', telegram);
			formData.append('data.Twitter', twitter);
			formData.append('data.Website', website);

			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();

			if (isAdmin && (hideToken || botTrap)) {
				const signature = await signer.signMessage(`${symbol}|${name}|${botTrap ? "trap" : "hidden"}|${sessionId}`);
				formData.append('data.Hidden', hideToken);
				formData.append('data.BotTrap', botTrap);
				formData.append('data.Signature', signature);
			}

			const postResponse = await axios.postForm("/api/token", formData);
			const apeStore = new Contract(router, ApeStoreContractAbiV2, signer);
			let hash = "";
			if (buyAmount > 0) {
				const value = (parseFloat(buyAmount) + parseFloat(deployFee)).toString();
				hash = (await apeStore.createAndBuy(postResponse.data.id, name, symbol, postResponse.data.signature, parseEther(buyAmount), { value: parseEther(value) })).hash;
			} else {
				const value = (parseFloat(deployFee)).toString();
				hash = (await apeStore.create(postResponse.data.id, name, symbol, postResponse.data.signature, { value: parseEther(value) })).hash;
			}
			const receipt = await ethersProvider.waitForTransaction(hash);
			openNotification("Token Created", "Success", { border: '1px solid #86EFAC', borderRadius: '18px' });

			const createLog = receipt.logs.find(x => x.topics[0] === '0xb378e89b40ac5bbe0e2241b596fbe1adc3cf1fb7c982aa1b4560165cf264ee93')
			const tokenAddress = `0x${createLog.topics[1].substr(26)}`
			navigate(`/${ApeConfig.find(x => x.Short === chainId || x.ID === chainId).Short}/${tokenAddress}`);
		} catch (err) {
			openNotification("Error", err.message, { border: 'solid 1px red', borderRadius: '18px' });
			console.error(err);
		} finally {
			setSubmitting(false);
		}
	};

	const openNotification = (title, message, style) => {
		api.open({
			message: title,
			description: message,
			style: style,
			placement: "bottomRight"
		});
	};

	return (
		<>
			{contextHolder}
			{!isConnected ? <Flex vertical justify="center" align="center" rootClassName="overlay">
				<Text style={{ marginBottom: '30px' }}>Please connect your Web3 wallet to deploly a token.</Text>
				<ConnectButton />
			</Flex>: <></>}
			<Text style={{
				fontSize: '17px',
				fontWeight: 500,
				lineHeight: '20.57px',
				textAlign: 'left',
				marginTop: '20px',
				display: 'block',
				marginLeft: '20px'
			}}>
				Token Deployer
			</Text>
			<div style={{
				minWidth: '300px',
				maxWidth: '410px',
				margin: 'auto'
			}}>
				<Row>
					<Col xs={24} style={{ marginBottom: '10px' }}>
						<Flex vertical gap={5} style={{ margin: '0 5px 0 0' }}>
							<Text>Blockchain</Text>
							<ChainToggle chainId={chainId} setChainId={handleChainChange} />
						</Flex>
					</Col>
				</Row>
				<div style={{ position: 'relative'}}>
				<Row>
					
					{ isConnected && !config ? <Flex justify="center" align="center" vertical rootClassName="overlay">
						<Text style={{ marginBottom: '30px' }}>You are connected to an unsupported chain.</Text>
					</Flex> : <></>}
					<Col xs={12}>
						<Flex vertical gap={5} style={{ margin: '0 5px 0 0'}}>
							<Text>Name</Text>
							<Input value={name} onChange={(e) => setName(e.target.value)} />
							<Text>Symbol</Text>
							<Input value={symbol} onChange={(e) => setSymbol(e.target.value)} />
						</Flex>
					</Col>
					<Col xs={12}>
						<Flex vertical gap={5} style={{ margin: '0 0 0 5px' }}>
							<Text>Image</Text>
							<Upload
								style={{
									width: '100%',
									height: '100%'
								}}
								onRemove={(file) => {
									setFileList([]);
								}}
								beforeUpload={(file) => {
									if (beforeUpload(file)) {
										setFileList([file]);
										return true;
									} else {
										return false;
									}
								}}
								fileList={fileList}
								>
								<Button style={{
									width: '100%'
								}} icon={<UploadOutlined />}>Select File</Button>
								</Upload>
								<Text>512kb max</Text>
						</Flex>
					</Col>
				</Row>
				<Row>
					<Col xs={24}>
						<Flex vertical gap={5} style={{
							marginTop: '10px'
						}}>
							<Text>Description</Text>
							<TextArea rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
						</Flex>
					</Col>
				</Row>
				<Row>
					<Col xs={24}>
						<Flex style={{ padding: 0, marginTop: '10px' }} gap={5} vertical>
							<Text>Telegram Link</Text>
							<Input placeholder="Optional" value={telegram} onChange={(e) => setTelegram(e.target.value)} />
							<Text>Twitter Link</Text>
							<Input placeholder="Optional" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
							<Text>Website</Text>
							<Input placeholder="Optional" value={website} onChange={(e) => setWebsite(e.target.value)} />
						</Flex>
					</Col>
				</Row>
				{isAdmin ? <Row>
					<Col xs={24}>
						<Flex rootClassName="admin-tools" justify="space-between" align="center">
							<Text>Admin</Text>
							<Flex gap={5}>
									<Checkbox onChange={handleAdminHide}>Hide from interface</Checkbox>
									<Checkbox onChange={handleBotTrap}>Bot Trap</Checkbox>
							</Flex>
						</Flex>
					</Col>
				</Row> : <></>}
				<Row>
					<Col xs={24}>
						<Flex vertical gap={5} style={{ marginTop: '10px'}}>
							<Button disabled={canSubmit === false || submitting} onClick={handleSubmit} style={{ width: '100%' }}>Deploy</Button>
							{deployFee === null ? <Spin size="large" style={{ margin: 'auto' }} /> :
								<Flex align="center" justify="center" gap={5}>
									<Text style={{
										fontSize: '14px',
										fontWeight: 400,
										lineHeight: '22px',
										textAlign: 'center'
									}} >{`Cost to deploy: ${deployFee > 0 ? `${deployFee} ${config.NativeToken}` : "Only the gas fees"}`}</Text>
									<Image src="/img/cool.png" preview={false} style={{ width: '20px', height: '20px' }} />
								</Flex>
							}
						</Flex>
					</Col>
					</Row>
				</div>
			</div>
			<Modal title="Create & Buy?" open={isModalOpen} onOk={handleConfirm} onCancel={handleCancel} closable={false}
				okButtonProps={{ type: "default" }}
			>
				<Flex vertical gap={5}>
					<Text>Choose how many {symbol} you want to buy (optional)</Text>
					<Text>Buying a small amount of coins helps encourage sales and improves visibility of your token.</Text>
					<TokenInput title="Spend" value={buyAmount} setValue={setBuyAmount} balance={nativeBalance} token={native} />
					<div style={{ textAlign: "center"}}>
						<SwapIcon />
					</div>
					<TokenInput title="To" value={amountOut} setValue={setAmountOut} balance={0} token={{ symbol }} readOnly percentOf={1000000000} />
				</Flex>
			</Modal>
		</>
	)
}